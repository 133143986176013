(function () {
    // Функция для проверки ширины экрана
    function isDesktop() {
        return window.innerWidth >= 991;
    }

    // Функция для создания и добавления стилей
    function addStyles() {
        const style = document.createElement("style");
        style.textContent = `
        /* Общие стили для блока .coverage */
        .coverage {
          position: relative;
        }

        /* Стили для язычка подсказки горячих клавиш */
        .hotkeys-tab {
          position: absolute;
          top: 5.5rem;
          right: 0px; /* Сдвиг на 20px вправо от предыдущих 20px */
          width: 30px;
          height: 30px;
          background-color: var(--green); /* Цвет фона кнопки */
          color: #fff;
          border-radius: 0; /* Убираем скругление */
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: transform 0.3s;
          z-index: 9;
          border: 1px solid white;
          @media(max-width: 992px) {
            display: none !important;
          }
        }

        /* Анимация shake, переименованная в hotkeys-shake */
        .hotkeys-tab:hover {
          animation: hotkeys-shake 0.5s;
        }

        @keyframes hotkeys-shake {
          0% { transform: translateX(0); }
          25% { transform: translateX(-5px); }
          50% { transform: translateX(5px); }
          75% { transform: translateX(-5px); }
          100% { transform: translateX(0); }
        }

        /* Стили для подсказки горячих клавиш */
        .hotkeys-tooltip {
          position: fixed;
          top: 5.5rem;
          right: -400px; /* Сдвиг на 20px вправо от предыдущих -300px */
          width: 320px;
          padding: 15px;
          background-color: #f9f9f9;
          box-shadow: 0 0 10px rgba(0,0,0,0.1);
          border-radius: 0; /* Убираем скругление */
          transition: right 0.5s;
          z-index: 8;


        }

        /* Активное состояние подсказки */
        .hotkeys-tooltip.active {
          right: 0px; /* Сдвиг на 20px вправо от предыдущих 20px */
        }

        .hotkeys-tooltip h3 {
          margin-top: 0;
          font-size: 16px;
        }

        .hotkeys-tooltip ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        .hotkeys-tooltip li {
          margin-bottom: 10px;
        }

        /* Скрываем элементы на мобильных устройствах */
        @media (max-width: 990px) {
          .hotkeys-tab,
          .hotkeys-tooltip {
            display: none;
          }
        }
      `;
        document.head.appendChild(style);
    }

    // Функция для создания и добавления HTML элементов
    function addElements() {
        const coverage = document.querySelector(".coverage");
        if (!coverage) {
            console.warn("Элемент с классом .coverage не найден.");
            return;
        }

        // Создаем язычок
        const hotkeysTab = document.createElement("div");
        hotkeysTab.classList.add("hotkeys-tab");
        hotkeysTab.innerHTML = `<span>?</span>`;

        // Создаем подсказку
        const hotkeysTooltip = document.createElement("div");
        hotkeysTooltip.classList.add("hotkeys-tooltip");
        hotkeysTooltip.innerHTML = `
        <h3>Горячие клавиши</h3>
        <ul>
          <li><strong>Shift + W:</strong> поиграть со шрифтами 🎨 </li>
          <li><strong>Shift + S:</strong> Змейка 🐍 </li>
          <li><strong>Shift + A:</strong> Крестики-нолики ❌⭕ </li>
          <li><strong>Shift + D:</strong> Тетрис 🧱</li>
          <!-- Добавьте свои горячие клавиши здесь -->
        </ul>
      `;
        //hotkeys горячие клавиши список

        // Добавляем элементы в блок .coverage
        coverage.appendChild(hotkeysTab);
        document.body.appendChild(hotkeysTooltip);

        // Логика отображения и скрытия подсказки
        hotkeysTab.addEventListener("click", function (event) {
            event.stopPropagation(); // Предотвращаем закрытие сразу после открытия
            hotkeysTooltip.classList.toggle("active");
        });

        // Закрытие подсказки при клике вне её области
        document.addEventListener("click", function (event) {
            if (
                !hotkeysTab.contains(event.target) &&
                !hotkeysTooltip.contains(event.target)
            ) {
                hotkeysTooltip.classList.remove("active");
            }
        });

        // Закрытие подсказки по клавише Esc
        document.addEventListener("keydown", function (event) {
            if (event.key === "Escape") {
                hotkeysTooltip.classList.remove("active");
            }
        });
    }

    // Инициализация скрипта
    function init() {
        if (isDesktop()) {
            addStyles();
            addElements();
        }
    }

    // Запускаем инициализацию после загрузки DOM
    if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", init);
    } else {
        init();
    }

    // Дополнительно: обновление при изменении размера окна
    window.addEventListener("resize", function () {
        const hotkeysTab = document.querySelector(".hotkeys-tab");
        const hotkeysTooltip = document.querySelector(".hotkeys-tooltip");
        if (isDesktop()) {
            if (
                hotkeysTab &&
                hotkeysTooltip &&
                !document.contains(hotkeysTab)
            ) {
                addElements();
            }
        } else {
            if (hotkeysTab) hotkeysTab.remove();
            if (hotkeysTooltip) hotkeysTooltip.remove();
        }
    });
})();
