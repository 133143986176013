document.addEventListener("DOMContentLoaded", () => {
    // Создаем контейнер для игры
    const gameContainer = document.createElement("div");
    gameContainer.style.position = "fixed";
    gameContainer.style.top = "100px";
    gameContainer.style.left = "100px";
    gameContainer.style.zIndex = "9999";
    gameContainer.style.display = "none"; // Скрываем контейнер по умолчанию
    gameContainer.style.background = "white";
    gameContainer.style.border = "1px solid #ccc";
    gameContainer.style.boxShadow = "0 0 10px rgba(0,0,0,0.5)";
    gameContainer.style.width = "420px";
    gameContainer.style.height = "600px"; // Увеличена высота для уведомлений и кнопок
    gameContainer.style.resize = "both"; // Возможность изменения размера
    gameContainer.style.overflow = "hidden"; // Скрываем переполнение

    // Создаем заголовок окна
    const gameHeader = document.createElement("div");
    gameHeader.style.width = "100%";
    gameHeader.style.height = "30px";
    gameHeader.style.background = "#005745";
    gameHeader.style.cursor = "move";
    gameHeader.style.display = "flex";
    gameHeader.style.justifyContent = "space-between";
    gameHeader.style.alignItems = "center";
    gameHeader.style.color = "white";
    gameHeader.style.boxSizing = "border-box";
    gameHeader.style.padding = "0 10px";

    // Текст заголовка
    const gameTitle = document.createElement("span");
    gameTitle.textContent = "Snake Game";
    gameTitle.style.userSelect = "none";

    // Кнопка закрытия
    const gameCloseButton = document.createElement("button");
    gameCloseButton.textContent = "X";
    gameCloseButton.style.background = "transparent";
    gameCloseButton.style.border = "none";
    gameCloseButton.style.color = "white";
    gameCloseButton.style.fontSize = "16px";
    gameCloseButton.style.cursor = "pointer";
    gameCloseButton.style.userSelect = "none";
    gameCloseButton.style.transition = "color 0.3s";

    // Добавляем анимацию при наведении на крестик
    gameCloseButton.addEventListener("mouseenter", () => {
        gameCloseButton.style.color = "red";
    });
    gameCloseButton.addEventListener("mouseleave", () => {
        gameCloseButton.style.color = "white";
    });

    // Добавляем заголовок и кнопку в header
    gameHeader.appendChild(gameTitle);
    gameHeader.appendChild(gameCloseButton);

    // Создаем canvas для игры
    const gameCanvas = document.createElement("canvas");
    gameCanvas.width = 400;
    gameCanvas.height = 400;
    gameCanvas.style.display = "block";
    gameCanvas.style.margin = "10px auto"; // Центрирование canvas
    gameCanvas.style.border = "2px solid #000"; // Добавляем рамку для игрового поля
    const ctx = gameCanvas.getContext("2d");

    // Создаем область для уведомлений и рекордов
    const infoContainer = document.createElement("div");
    infoContainer.style.width = "100%";
    infoContainer.style.textAlign = "center";
    infoContainer.style.padding = "10px";
    infoContainer.style.boxSizing = "border-box";

    const scoreDisplay = document.createElement("div");
    scoreDisplay.textContent = "Score: 0";
    scoreDisplay.style.marginBottom = "5px";

    const highScoreDisplay = document.createElement("div");
    highScoreDisplay.textContent = "High Score: 0";
    highScoreDisplay.style.marginBottom = "10px";

    const messageDisplay = document.createElement("div");
    messageDisplay.textContent = "";
    messageDisplay.style.color = "red";
    messageDisplay.style.marginBottom = "10px";

    // Создаем кнопку рестарта
    const restartButton = document.createElement("button");
    restartButton.textContent = "Restart Game";
    restartButton.style.padding = "5px 10px";
    restartButton.style.border = "none";
    restartButton.style.background = "#005745";
    restartButton.style.color = "white";
    restartButton.style.cursor = "pointer";
    restartButton.style.borderRadius = "4px";
    restartButton.style.transition = "background 0.3s";

    // Добавляем анимацию при наведении на кнопку
    restartButton.addEventListener("mouseenter", () => {
        restartButton.style.background = "#005a9e";
    });
    restartButton.addEventListener("mouseleave", () => {
        restartButton.style.background = "#005745";
    });

    // Собираем структуру элементов
    infoContainer.appendChild(scoreDisplay);
    infoContainer.appendChild(highScoreDisplay);
    infoContainer.appendChild(messageDisplay);
    infoContainer.appendChild(restartButton);

    gameContainer.appendChild(gameHeader);
    gameContainer.appendChild(gameCanvas);
    gameContainer.appendChild(infoContainer);
    document.body.appendChild(gameContainer);

    // Инициализация игры
    const tileSize = 20;
    const canvasWidth = gameCanvas.width;
    const canvasHeight = gameCanvas.height;
    const gridWidth = canvasWidth / tileSize;
    const gridHeight = canvasHeight / tileSize;

    let snake, direction, food, score, highScore, gameInterval;

    // Загрузка рекорда из localStorage
    highScore = parseInt(localStorage.getItem("snakeHighScore")) || 0;
    highScoreDisplay.textContent = `High Score: ${highScore}`;

    // Функция инициализации игры
    function initGame() {
        snake = [{ x: 10, y: 10 }];
        direction = { x: 1, y: 0 };
        food = {
            x: Math.floor(Math.random() * gridWidth),
            y: Math.floor(Math.random() * gridHeight),
        };
        score = 0;
        scoreDisplay.textContent = `Score: ${score}`;
        messageDisplay.textContent = "";
        if (gameInterval) clearInterval(gameInterval);
        gameInterval = setInterval(gameLoop, 100);
    }

    // Функция рисования еды
    function drawFood() {
        ctx.fillStyle = "red";
        ctx.fillRect(food.x * tileSize, food.y * tileSize, tileSize, tileSize);
    }

    // Функция рисования змейки
    function drawSnake() {
        ctx.fillStyle = "green";
        snake.forEach((segment) => {
            ctx.fillRect(
                segment.x * tileSize,
                segment.y * tileSize,
                tileSize,
                tileSize
            );
        });
    }

    // Функция обновления положения змейки
    function updateSnake() {
        const head = {
            x: snake[0].x + direction.x,
            y: snake[0].y + direction.y,
        };

        // Перенос змейки через стены
        if (head.x < 0) head.x = gridWidth - 1;
        if (head.x >= gridWidth) head.x = 0;
        if (head.y < 0) head.y = gridHeight - 1;
        if (head.y >= gridHeight) head.y = 0;

        // Проверка на столкновение с собой
        if (
            snake.some(
                (segment) => segment.x === head.x && segment.y === head.y
            )
        ) {
            clearInterval(gameInterval);
            messageDisplay.textContent = `Game Over! Your score: ${score}`;
            // Обновление рекорда
            if (score > highScore) {
                highScore = score;
                localStorage.setItem("snakeHighScore", highScore);
                highScoreDisplay.textContent = `High Score: ${highScore}`;
                messageDisplay.textContent += " - New High Score!";
            }
            return;
        }

        snake.unshift(head);

        // Проверка на еду
        if (head.x === food.x && head.y === food.y) {
            score++;
            scoreDisplay.textContent = `Score: ${score}`;
            placeFood();
        } else {
            snake.pop();
        }
    }

    // Функция размещения еды
    function placeFood() {
        let newFood;
        do {
            newFood = {
                x: Math.floor(Math.random() * gridWidth),
                y: Math.floor(Math.random() * gridHeight),
            };
        } while (
            snake.some(
                (segment) => segment.x === newFood.x && segment.y === newFood.y
            )
        );
        food = newFood;
    }

    // Главная функция игры
    function gameLoop() {
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        drawSnake();
        drawFood();
        updateSnake();
    }

    // Управление змейкой с клавиатуры
    function handleKeyDown(e) {
        switch (e.code) {
            case "ArrowUp":
                if (direction.y === 0) direction = { x: 0, y: -1 };
                break;
            case "ArrowDown":
                if (direction.y === 0) direction = { x: 0, y: 1 };
                break;
            case "ArrowLeft":
                if (direction.x === 0) direction = { x: -1, y: 0 };
                break;
            case "ArrowRight":
                if (direction.x === 0) direction = { x: 1, y: 0 };
                break;
        }
    }

    // Открытие окна для игры по Shift + S
    document.addEventListener("keydown", (e) => {
        if (e.shiftKey && e.code === "KeyS") {
            e.preventDefault();
            gameContainer.style.display =
                gameContainer.style.display === "none" ? "block" : "none";
            if (gameContainer.style.display === "block") {
                if (!gameInterval) {
                    initGame(); // Запускаем игру при открытии окна
                }
            } else {
                clearInterval(gameInterval);
                gameInterval = null; // Останавливаем игру при закрытии окна
            }
        }
    });

    // Обработчик кнопки рестарта
    restartButton.addEventListener("click", () => {
        initGame();
    });

    // Добавляем функциональность закрытия окна
    gameCloseButton.addEventListener("click", () => {
        gameContainer.style.display = "none";
        clearInterval(gameInterval);
        gameInterval = null; // Останавливаем игру при закрытии окна
    });

    // Реализация перетаскивания окна
    let isDragging = false;
    let offsetX, offsetY;

    gameHeader.addEventListener("mousedown", (e) => {
        isDragging = true;
        offsetX = e.clientX - gameContainer.offsetLeft;
        offsetY = e.clientY - gameContainer.offsetTop;
        document.body.style.userSelect = "none"; // Отключаем выделение текста на странице
    });

    document.addEventListener("mousemove", (e) => {
        if (isDragging) {
            gameContainer.style.left = e.clientX - offsetX + "px";
            gameContainer.style.top = e.clientY - offsetY + "px";
        }
    });

    document.addEventListener("mouseup", () => {
        isDragging = false;
        document.body.style.userSelect = "auto"; // Включаем выделение текста на странице
    });

    // Отключение прокрутки страницы при нажатии стрелок вверх и вниз, когда окно игры открыто
    document.addEventListener("keydown", (e) => {
        const isGameOpen = gameContainer.style.display === "block";
        const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
        if (isGameOpen && arrowKeys.includes(e.code)) {
            e.preventDefault();
            handleKeyDown(e);
        }
    });
});
