document.addEventListener("DOMContentLoaded", () => {
    (function () {
        // Функция для добавления CSS через JavaScript
        function injectStyles() {
            const styles = `
                .footer__interactive-square {
                    width: 1.875rem; /* 30px */
                    height: 1.875rem; /* 30px */
                    transition: transform 0.3s ease;
                }

                .w-layout-grid.footer__square-grid {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 1.875rem);
                }
            `;
            const styleSheet = document.createElement("style");
            styleSheet.type = "text/css";
            styleSheet.innerText = styles;
            document.head.appendChild(styleSheet);
        }

        // Функция для инициализации интерактивности
        function initInteractiveFooter() {
            const squares = document.querySelectorAll(
                ".footer__interactive-square"
            );
            if (squares.length === 0) return; // Если нет ячеек, выйти

            const threshold = 150; // Радиус действия в пикселях
            const maxMove = 18; // Максимальное смещение в пикселях
            let mouseX = 0;
            let mouseY = 0;
            let ticking = false;

            // Обработчик движения мыши
            function onMouseMove(e) {
                mouseX = e.clientX;
                mouseY = e.clientY;

                if (!ticking) {
                    window.requestAnimationFrame(updateSquares);
                    ticking = true;
                }
            }

            // Функция обновления позиций ячеек
            function updateSquares() {
                squares.forEach((square) => {
                    const rect = square.getBoundingClientRect();
                    const squareX = rect.left + rect.width / 2;
                    const squareY = rect.top + rect.height / 2;

                    const dx = mouseX - squareX;
                    const dy = mouseY - squareY;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    if (distance < threshold) {
                        // Вычисляем силу притяжения
                        const force = (threshold - distance) / threshold;
                        const angle = Math.atan2(dy, dx);
                        const moveX = Math.cos(angle) * force * maxMove;
                        const moveY = Math.sin(angle) * force * maxMove;

                        square.style.transform = `translate(${moveX}px, ${moveY}px)`;
                    } else {
                        square.style.transform = "translate(0, 0)";
                    }
                });
                ticking = false;
            }

            // Добавляем слушатель события мыши
            document.addEventListener("mousemove", onMouseMove);
        }

        // Проверяем ширину экрана и инициализируем эффект, если нужно
        if (window.innerWidth >= 991) {
            injectStyles();
            initInteractiveFooter();
        }

        // Дополнительно: Обработка изменения размера окна
        window.addEventListener("resize", () => {
            if (
                window.innerWidth >= 991 &&
                !document.querySelector("style.footer-interactive-styles")
            ) {
                injectStyles();
                initInteractiveFooter();
            }
            // Если ширина стала меньше 991px, можно добавить логику для отключения эффекта, если требуется
        });
    })();
});
