document.addEventListener("DOMContentLoaded", () => {
    // The Content class handles the pixelation effect
    const isMobileDevice = () => {
        return window.matchMedia("(max-width: 991px)").matches;
    };

    if (isMobileDevice()) return;
    // Select all .team__card-img-wrapper elements
    const imgWrappers = document.querySelectorAll(".team__card-img-wrapper");

    imgWrappers.forEach((wrapper) => {
        // Find the <img> element within the current wrapper
        const imgElement = wrapper.querySelector(".team__card-img");

        // Check if the <img> element exists
        if (imgElement) {
            // Get the image URL from the src attribute
            const imgUrl = imgElement.src;

            // Create a new <div> element
            const divElement = document.createElement("div");

            // Apply the background image style to the <div>
            divElement.classList.add("canvas-wrap");
            divElement.style.backgroundImage = `url(${imgUrl})`;
            divElement.style.backgroundSize = "cover"; // Ensure the image covers the div
            divElement.style.backgroundPosition = "center"; // Center the image in the div
            divElement.style.backgroundRepeat = "no-repeat"; // Prevent repeating the image
            divElement.style.width = "auto"; // Set width based on <img> dimensions
            divElement.style.height = 250 + "px"; // Set height based on <img> dimensions

            // Remove the <img> element from the wrapper
            imgElement.remove();

            // Append the new <div> to the wrapper
            wrapper.appendChild(divElement);
        } else {
            console.warn("No image element found within the wrapper.");
        }
    });

    class Content {
        // DOM elements
        DOM = {
            el: null,
            canvasWrap: null,
            canvas: null,
            inner: null,
        };
        // the image source/url
        imageSrc;
        // canvas image
        img;
        // the image ratio
        imgRatio;
        // canvas context
        ctx;
        // The pixelation factor values determine the level of
        // pixelation at each step of the effect.
        // To make the effect more prominent, we start with
        // smaller values initially to keep the big blocks
        // visible for a longer time.
        // Towards the end we don't add many values as
        // we want the sharpening up to happen quickly here.
        pxFactorValues = [100, 25, 20, 14, 9];
        pxIndex = 0;

        /**
         * Constructor for the Content class.
         * Accepts a DOM element representing the content element.
         */
        constructor(DOM_el) {
            // Initialize DOM elements
            this.DOM.el = DOM_el;
            this.DOM.canvasWrap = this.DOM.el.querySelector(".canvas-wrap");
            this.DOM.inner = this.DOM.el.querySelector(".content__inner");

            // Extract the image source from the background image style
            this.imageSrc = this.DOM.canvasWrap.style.backgroundImage
                .match(/\((.*?)\)/)[1]
                .replace(/('|")/g, "");

            // Create a canvas element and append it to the canvasWrap element if it doesn't already exist
            if (!this.DOM.canvasWrap.querySelector("canvas")) {
                this.DOM.canvas = document.createElement("canvas");
                this.DOM.canvasWrap.appendChild(this.DOM.canvas);

                // Get the 2D rendering context of the canvas
                this.ctx = this.DOM.canvas.getContext("2d");

                // Create a new Image object and load the image source
                this.img = new Image();
                this.img.src = this.imageSrc;

                // Once the image is loaded, perform necessary calculations and rendering
                this.img.onload = () => {
                    const imgWidth = this.img.width;
                    const imgHeight = this.img.height;
                    this.imgRatio = imgWidth / imgHeight;
                    this.setCanvasSize();
                    this.render();
                    // Set up event listeners and triggers
                    this.initEvents();
                };
            }
        }

        /**
         * Sets up event listeners and hover events.
         */
        initEvents() {
            // Resize event handler
            window.addEventListener("resize", () => {
                this.setCanvasSize();
                this.render();
            });

            // show canvasWrap when the element enters the viewport
            gsap.set(this.DOM.canvasWrap, {
                opacity: 1,
            });

            this.animatePixels();

            // Hover events to animate pixelation effect
            this.DOM.el.addEventListener("mouseenter", () => {
                this.pxFactorValues = [1, 2, 4, 9, 100];
                this.pxIndex = 0;
                this.animatePixels();
            });

            this.DOM.el.addEventListener("mouseleave", () => {});
        }

        /**
         * Sets the canvas size based on the dimensions
         * of the canvasWrap element.
         */
        setCanvasSize() {
            this.DOM.canvas.width = this.DOM.canvasWrap.offsetWidth;
            this.DOM.canvas.height = this.DOM.canvasWrap.offsetHeight;
        }

        /**
         * Renders the image on the canvas.
         * Applies the pixelation effect based on the pixel factor.
         */
        render() {
            const offsetWidth = this.DOM.canvasWrap.offsetWidth;
            const offsetHeight = this.DOM.canvasWrap.offsetHeight;
            // increase a bit to not have a gap in the end of the image
            // when we have big pizel sizes
            const w = offsetWidth + offsetWidth * 0.05;
            const h = offsetHeight + offsetHeight * 0.05;

            // Calculate the dimensions and position for rendering the image
            // within the canvas based on the image aspect ratio.
            let newWidth = w;
            let newHeight = h;
            let newX = 0;
            let newY = 0;

            // Adjust the dimensions and position if the image
            // aspect ratio is different from the canvas aspect ratio
            if (newWidth / newHeight > this.imgRatio) {
                newHeight = Math.round(w / this.imgRatio);
                // let's keep Y at 0 because we want the pixels to not
                // be cut off at the top. Uncomment if you want the
                // image to be centered.
                // newY = (h - newHeight) / 2;
            } else {
                newWidth = Math.round(h * this.imgRatio);
                newX = (w - newWidth) / 2;
            }

            // Get the pixel factor based on the current index
            let pxFactor = this.pxFactorValues[this.pxIndex];
            const size = pxFactor * 0.01;

            // Turn off image smoothing to achieve the pixelated effect
            this.ctx.mozImageSmoothingEnabled = size === 1 ? true : false;
            this.ctx.webkitImageSmoothingEnabled = size === 1 ? true : false;
            this.ctx.imageSmoothingEnabled = size === 1 ? true : false;

            // Clear the canvas
            this.ctx.clearRect(
                0,
                0,
                this.DOM.canvas.width,
                this.DOM.canvas.height
            );

            // Draw the original image at a fraction of the final size
            this.ctx.drawImage(this.img, 0, 0, w * size, h * size);

            // Enlarge the minimized image to full size
            this.ctx.drawImage(
                this.DOM.canvas,
                0,
                0,
                w * size,
                h * size,
                newX,
                newY,
                newWidth,
                newHeight
            );
        }

        /**
         * Animates the pixelation effect.
         * Renders the image with decreasing pixelation factor at each step.
         */
        animatePixels() {
            if (this.pxIndex < this.pxFactorValues.length) {
                // Decrease the pixelation factor and continue animating
                setTimeout(
                    () => {
                        // Render the image with the current pixelation factor
                        this.render();
                        this.pxIndex++;
                        this.animatePixels();
                    },
                    this.pxIndex === 0 ? 300 : 80
                ); // The first time should be the longest.
            }
        }
    }

    // Preload images
    const preloadImages = (selector = "img") => {
        return new Promise((resolve) => {
            imagesLoaded(
                document.querySelectorAll(selector),
                { background: true },
                resolve
            );
        });
    };

    gsap.registerPlugin(ScrollTrigger);

    // .content elements
    const contentElems = [
        ...document.querySelectorAll(".team__card-img-wrapper"),
    ];
    const elemsWrapper = [...document.querySelectorAll(".team__card")];
    let flag = true;

    if (flag) {
        contentElems.forEach((elem, index) => {
            ScrollTrigger.create({
                trigger: elemsWrapper[index],
                start: "top bottom",
                onEnter: () => {
                    flag = false;
                    new Content(elem);

                    // Preload images then remove loader (loading class) from body
                    preloadImages(".team__card-img-wrapper div").then(() =>
                        document.body.classList.remove("loading")
                    );
                },
            });
        });
    }
});
