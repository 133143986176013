document.addEventListener("DOMContentLoaded", function() {
    const template = document.querySelector(".project-template__aside");
    const targetElement = document.querySelector(".project-template__article");

    document.querySelectorAll(".rich-text-block.w-richtext").forEach(item => item.style.opacity = 1)


    // Логируем, если целевые элементы не найдены
    if (template === undefined || template === null) {
        return;
    }

    if (targetElement === undefined || targetElement === null) {
        return;
    }

    // Логируем успешное нахождение элементов
    console.log("Template and target elements found. Proceeding...");

    // Function to replace all occurrences of the pattern \\\.*\\\
    function replacePattern(text) {
        // Regular expression to find all occurrences of the pattern \\\.*\\\
        const pattern = /\\\\\\.*?\\\\\\/g;

        // Replace the pattern with the div with class 'hidden-text'
        const replacedText = text.replace(pattern, function(match) {
            console.log(`Pattern found: ${match}`);
            return `<span class="hidden-text">${match}</span>`;
        });

        return replacedText;
    }

    // Логируем перед началом замены в целевых элементах

    // Traverse all children of the targetElement and replace the pattern in each
    targetElement.querySelectorAll("*").forEach((element) => {
        if (element.innerHTML) {
            element.innerHTML = replacePattern(element.innerHTML);
        }
    });



});

