document.addEventListener("DOMContentLoaded", () => {
    gsap.registerPlugin(ScrollTrigger, TextPlugin, SplitText);
    const cards = document.querySelectorAll(".numbers__card");

    if (cards.length === 0) {
        return;
    }

    gsap.set(".numbers__card div", {
        opacity: 0,
    });

    cards.forEach((card) => {
        gsap.fromTo(
            card,
            { scaleY: 0 },
            {
                scaleY: 1,
                scrollTrigger: {
                    trigger: card,
                    start: "top 80%", // When the top of the card is 80% from the top of the viewport
                },

                onComplete: () => {
                    animateCardText(card);
                },
            }
        );
    });
});

function animateCardText(card) {
    const allElems = card.querySelectorAll("*");
    const header = card.querySelector("h2");
    const divs = card.querySelectorAll("div:not(.card__header-wrapper)");

    const headerSplit = new SplitText(header, { type: "chars, lines" });

    gsap.to(allElems, {
        opacity: 1,
    });

    gsap.set(headerSplit.lines, {
        overflow: "hidden",
    });

    gsap.fromTo(
        headerSplit.chars,
        { y: "100%" }, // starting state
        { y: "0%", stagger: 0.05, duration: 0.3, delay: 0.2 }
    );

    divs.forEach((div) => {
        const divCplit = new SplitText(div, { type: "words, lines" });

        gsap.set(divCplit.lines, {
            overflow: "hidden",
        });

        gsap.fromTo(
            divCplit.words,
            { y: "100%" }, // starting state
            { y: "0%", stagger: 0.05, duration: 0.2, delay: 0.7 }
        );
    });
}
