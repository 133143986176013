document.addEventListener("DOMContentLoaded", () => {
    gsap.registerPlugin(ScrollTrigger, TextPlugin, SplitText);
    const cards = document.querySelectorAll(".projects__card");

    if (cards.length === 0) {
        return;
    }

    gsap.set(".projects__card div", {
        opacity: 0,
    });

    cards.forEach((card) => {
        gsap.fromTo(
            card,
            { scaleY: 0 },
            {
                scaleY: 1,
                scrollTrigger: {
                    trigger: card,
                    start: "top 80%", // When the top of the card is 80% from the top of the viewport
                },

                onComplete: () => {
                    animateProjectText(card);
                },
            }
        );
    });
});

function animateProjectText(card) {
    const header = card.querySelector(".project__card-name");
    const tags = card.querySelectorAll(".project__card-tag");

    const headerSplit = new SplitText(header, { type: "words, lines" });

    gsap.set(card.querySelectorAll("div"), {
        opacity: 1,
    });

    gsap.set(headerSplit.lines, {
        overflow: "hidden",
    });

    gsap.fromTo(
        headerSplit.chars,
        { y: "100%" }, // starting state
        { y: "0%", stagger: 0.05, duration: 0.3, delay: 0.2 }
    );

    tags.forEach((tag) => {
        const tagCplit = new SplitText(tag, { type: "words, lines" });

        gsap.set(tagCplit.lines, {
            overflow: "hidden",
        });

        gsap.fromTo(
            tagCplit.words,
            { y: "100%" }, // starting state
            { y: "0%", stagger: 0.05, duration: 0.2, delay: 0.7 }
        );
    });
}
