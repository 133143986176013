document.addEventListener("DOMContentLoaded", () => {
    gsap.registerPlugin(ScrollTrigger, TextPlugin, SplitText);

    const triggerElems = document.querySelectorAll(".scroll--trigger");

    if (triggerElems.length === 0) {
        return 0;
    }

    const vh = (coef) => window.innerHeight * (coef / 100);

    let counter = 1;

    const asideTitles = [
        "Цифры и факты",
        "Услуги",
        "Наши клиенты",
        "Команда",
        "Проекты",
    ];

    for (let i = 0; i < triggerElems.length; i++) {
        ScrollTrigger.create({
            trigger: triggerElems[i],
            start: -vh(8) + " top",
            markers: true,
            onEnter: () => {
                if (counter < asideTitles.length) {
                    counter += 1;
                    updateText(
                        asideTitles[counter - 1].toString(),
                        counter.toString()
                    );
                }
            },
            onLeaveBack: () => {
                if (counter > 1) {
                    counter -= 1;
                    updateText(
                        asideTitles[counter - 1].toString(),
                        counter.toString()
                    );
                }
            },
        });
    }

    function updateText(newTitle, newCounter) {
        const titleElem = document.querySelector(".aside__title-text");
        const counterElem = document.querySelector(".aside__counter-text");

        const tl = gsap.timeline();

        // Ensure counter always has two digits
        const formattedCounter = newCounter.padStart(2, "0");

        let titleSplit = new SplitText(titleElem, { type: "chars" });
        let counterSplit = new SplitText(counterElem, { type: "chars" });

        // Slide up previous title text
        tl.fromTo(
            titleSplit.chars,
            { x: "0%" }, // starting state
            {
                duration: 0.15,
                x: "100%", // ending state
                ease: "linear",
                stagger: 0.05,
                onComplete: () => {
                    try {
                        // Replace title element with a clone of itself and update the text
                        const newTitleElem = titleElem.cloneNode(true);
                        newTitleElem.textContent = newTitle;
                        titleElem.parentNode.replaceChild(
                            newTitleElem,
                            titleElem
                        );

                        // Re-initialize SplitText on the new title element
                        titleSplit = new SplitText(newTitleElem, {
                            type: "chars",
                        });

                        // Slide in new title text
                        gsap.fromTo(
                            titleSplit.chars,
                            { x: "-100%" }, // starting state
                            {
                                duration: 0.15,
                                stagger: 0.05,
                                x: "0%", // ending state
                                ease: "linear",
                            }
                        );
                    } catch (error) {
                        console.error("Error updating title:", error);
                    }
                },
            },
            "outLabel"
        );

        // Slide up previous counter text
        tl.fromTo(
            counterSplit.chars,
            { x: "0%" }, // starting state
            {
                duration: 0.15,
                x: "100%", // ending state
                ease: "linear",
                stagger: 0.05,
                onComplete: () => {
                    try {
                        // Replace counter element with a clone of itself and update the text
                        const newCounterElem = counterElem.cloneNode(true);
                        newCounterElem.textContent = formattedCounter;
                        counterElem.parentNode.replaceChild(
                            newCounterElem,
                            counterElem
                        );

                        // Re-initialize SplitText on the new counter element
                        counterSplit = new SplitText(newCounterElem, {
                            type: "chars",
                        });

                        // Slide in new counter text
                        gsap.fromTo(
                            counterSplit.chars,
                            { x: "-100%" }, // starting state
                            {
                                duration: 0.15,
                                stagger: 0.05,
                                x: "0%", // ending state
                                ease: "linear",
                            }
                        );
                    } catch (error) {
                        console.error("Error updating counter:", error);
                    }
                },
            },
            "outLabel"
        );
    }
});
