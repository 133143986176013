// Function to handle the word flickering effect, accepts an array of words and a DOM element
const flicker = (words, element) => {
    let part = "", // Part of the current word to be displayed
        i = 0, // Index of the current word in the words array
        offset = 0, // Current position in the word being displayed
        len = words.length, // Total number of words
        forwards = true, // Direction of typing (forwards or backwards)
        skipCount = 0, // Counter for delay at the end of each word
        skipDelay = 15, // Delay to pause before deleting the word
        speed = 100; // Speed of typing

    setInterval(() => {
        if (forwards) {
            // If typing forwards and the end of the word is reached
            if (offset >= words[i].length) {
                skipCount++;
                if (skipCount === skipDelay) {
                    forwards = false; // Switch to deleting the word
                    skipCount = 0; // Reset the skip counter
                }
            }
        } else {
            // If deleting the word and the beginning of the word is reached
            if (offset === 0) {
                forwards = true; // Switch to typing the next word
                i++; // Move to the next word in the array
                offset = 0;
                if (i >= len) {
                    i = 0; // Loop back to the first word if the end of the array is reached
                }
            }
        }

        // Get the current part of the word to be displayed
        part = words[i].substring(0, offset) + " █";

        if (skipCount === 0) {
            // If not in the skip delay, adjust the offset
            if (forwards) {
                offset++; // Increase offset to type forwards
            } else {
                offset--; // Decrease offset to delete backwards
            }
        }

        // Update the DOM element with the current part and the cursor
        element.innerHTML = part;
    }, speed); // Set the interval speed for the word flickering effect
};

// Run the wordflick function after the DOM is fully loaded
document.addEventListener("DOMContentLoaded", () => {
    const flickElements = document.querySelectorAll("[data-wordflick]"); // Select the element to update
    if (flickElements === undefined || flickElements === null) {
        return;
    }
    const words1 = [
        "Нам не важен масштаб проблем, мы знаем принципы их решения",
    ];

    const words2 = [
        "Иск, приложения, для сторон дубликаты",
        "В документе доводы, цифры и факты",
        "Не дрогнет и бровью юрист Квадрата",
        "Верит в силу позиции и ждет результата",
    ];

    const words = [words1, words2];

    flickElements.forEach((el, index) => {
        flicker(words[index], el); // Pass the array of words and the selected element to the wordflick function
    });
});
