document.addEventListener("DOMContentLoaded", function () {
    // Создаем контейнер для игры
    const gameContainer = document.createElement("div");
    gameContainer.style.position = "fixed";
    gameContainer.style.top = "50px"; // Увеличили высоту окна
    gameContainer.style.left = "100px";
    gameContainer.style.zIndex = "9999";
    gameContainer.style.display = "none"; // Скрываем контейнер по умолчанию
    gameContainer.style.background = "#000"; // Черный фон для лампового эффекта
    gameContainer.style.border = "2px solid #00FF00"; // Зеленая рамка
    gameContainer.style.boxShadow = "0 0 20px rgba(0,255,0,0.7)"; // Зеленое свечение
    gameContainer.style.width = "320px";
    gameContainer.style.height = "400px"; // Увеличенная высота
    gameContainer.style.resize = "both"; // Возможность изменения размера
    gameContainer.style.overflow = "hidden"; // Скрываем переполнение

    // Создаем заголовок окна
    const gameHeader = document.createElement("div");
    gameHeader.style.width = "100%";
    gameHeader.style.height = "40px"; // Увеличена высота заголовка
    gameHeader.style.background = "#003300"; // Темно-зеленый цвет
    gameHeader.style.cursor = "move";
    gameHeader.style.display = "flex";
    gameHeader.style.justifyContent = "space-between";
    gameHeader.style.alignItems = "center";
    gameHeader.style.color = "#00FF00"; // Зеленый цвет текста
    gameHeader.style.boxSizing = "border-box";
    gameHeader.style.padding = "0 15px";

    // Текст заголовка
    const gameTitle = document.createElement("span");
    gameTitle.textContent = "Крестики-нолики";
    gameTitle.style.userSelect = "none";
    gameTitle.style.fontFamily = "'Courier New', Courier, monospace"; // Моноширинный шрифт

    // Кнопка закрытия
    const gameCloseButton = document.createElement("button");
    gameCloseButton.textContent = "✖"; // Используем символ крестика
    gameCloseButton.style.background = "transparent";
    gameCloseButton.style.border = "none";
    gameCloseButton.style.color = "#00FF00";
    gameCloseButton.style.fontSize = "20px";
    gameCloseButton.style.cursor = "pointer";
    gameCloseButton.style.userSelect = "none";
    gameCloseButton.style.transition = "color 0.3s";

    // Добавляем анимацию при наведении на крестик
    gameCloseButton.addEventListener("mouseenter", () => {
        gameCloseButton.style.color = "#FF0000"; // Красный цвет при наведении
    });
    gameCloseButton.addEventListener("mouseleave", () => {
        gameCloseButton.style.color = "#00FF00"; // Возврат к зеленому
    });

    // Добавляем заголовок и кнопку в header
    gameHeader.appendChild(gameTitle);
    gameHeader.appendChild(gameCloseButton);

    // Создаем игровое поле
    const gameBoard = document.createElement("div");
    gameBoard.style.display = "grid";
    gameBoard.style.gridTemplateColumns = "repeat(3, 1fr)";
    gameBoard.style.gridTemplateRows = "repeat(3, 1fr)";
    gameBoard.style.width = "280px";
    gameBoard.style.height = "280px";
    gameBoard.style.margin = "10px auto";
    gameBoard.style.gap = "5px";
    gameBoard.style.border = "2px solid #00FF00"; // Зеленая рамка вокруг игрового поля

    // Создаем ячейки игрового поля
    const cells = [];
    for (let i = 0; i < 9; i++) {
        const cell = document.createElement("div");
        cell.style.background = "#001a00"; // Темно-зеленый фон ячеек
        cell.style.border = "1px solid #00FF00"; // Зеленая рамка ячеек
        cell.style.display = "flex";
        cell.style.alignItems = "center";
        cell.style.justifyContent = "center";
        cell.style.fontSize = "48px";
        cell.style.cursor = "pointer";
        cell.style.fontFamily = "'Courier New', Courier, monospace"; // Моноширинный шрифт
        cell.style.color = "#00FF00"; // Зеленый цвет символов
        cell.style.textShadow = "0 0 5px #00FF00"; // Светящийся эффект текста
        cell.dataset.index = i;
        gameBoard.appendChild(cell);
        cells.push(cell);
    }

    // Создаем область для уведомлений и кнопок
    const infoContainer = document.createElement("div");
    infoContainer.style.width = "100%";
    infoContainer.style.textAlign = "center";
    infoContainer.style.padding = "10px";
    infoContainer.style.boxSizing = "border-box";

    const messageDisplay = document.createElement("div");
    messageDisplay.textContent = "Ваш ход";
    messageDisplay.style.marginBottom = "10px";
    messageDisplay.style.color = "#00FF00";
    messageDisplay.style.fontFamily = "'Courier New', Courier, monospace"; // Моноширинный шрифт
    messageDisplay.style.textShadow = "0 0 3px #00FF00"; // Светящийся эффект текста

    const restartButton = document.createElement("button");
    restartButton.textContent = "Начать заново";
    restartButton.style.padding = "8px 16px";
    restartButton.style.border = "2px solid #00FF00";
    restartButton.style.background = "transparent";
    restartButton.style.color = "#00FF00";
    restartButton.style.cursor = "pointer";
    restartButton.style.borderRadius = "4px";
    restartButton.style.fontFamily = "'Courier New', Courier, monospace"; // Моноширинный шрифт
    restartButton.style.transition = "background 0.3s, color 0.3s";

    // Добавляем анимацию при наведении на кнопку
    restartButton.addEventListener("mouseenter", () => {
        restartButton.style.background = "#00FF00";
        restartButton.style.color = "#000";
    });
    restartButton.addEventListener("mouseleave", () => {
        restartButton.style.background = "transparent";
        restartButton.style.color = "#00FF00";
    });

    // Собираем структуру элементов
    infoContainer.appendChild(messageDisplay);
    infoContainer.appendChild(restartButton);

    gameContainer.appendChild(gameHeader);
    gameContainer.appendChild(gameBoard);
    gameContainer.appendChild(infoContainer);
    document.body.appendChild(gameContainer);

    // Инициализация игры
    let board = Array(9).fill(null);
    let isPlayerTurn = true; // Игрок начинает первым
    let gameActive = true;

    // Функция для обновления сообщения
    function updateMessage(message) {
        messageDisplay.textContent = message;
    }

    // Функция для проверки победы
    function checkWin(player) {
        const winPatterns = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6],
        ];
        return winPatterns.some((pattern) =>
            pattern.every((index) => board[index] === player)
        );
    }

    // Функция для проверки ничьей
    function checkDraw() {
        return board.every((cell) => cell !== null);
    }

    // Функция для хода игрока
    function playerMove(index) {
        if (board[index] === null && gameActive && isPlayerTurn) {
            board[index] = "X";
            cells[index].textContent = "X";
            if (checkWin("X")) {
                updateMessage("Вы выиграли!");
                gameActive = false;
                return;
            }
            if (checkDraw()) {
                updateMessage("Ничья!");
                gameActive = false;
                return;
            }
            isPlayerTurn = false;
            updateMessage("Ход компьютера...");
            setTimeout(computerMove, 500);
        }
    }

    // Функция для хода компьютера (ИИ)
    function computerMove() {
        if (!gameActive) return;

        // Уменьшаем интеллект ИИ: добавляем вероятность случайного хода
        const RANDOM_MOVE_PROBABILITY = 0.3; // 30% шанс сделать случайный ход

        if (Math.random() < RANDOM_MOVE_PROBABILITY) {
            makeRandomMove();
            return;
        }

        // Попытка выиграть
        let move = findBestMove("O");

        // Если не найдено, попытка заблокировать игрока
        if (move === -1) {
            move = findBestMove("X");
        }

        // Если всё еще не найдено, выбираем случайный ход
        if (move === -1) {
            makeRandomMove();
            return;
        }

        if (move !== -1) {
            board[move] = "O";
            cells[move].textContent = "O";
            if (checkWin("O")) {
                updateMessage("Компьютер выиграл!");
                gameActive = false;
                return;
            }
            if (checkDraw()) {
                updateMessage("Ничья!");
                gameActive = false;
                return;
            }
            isPlayerTurn = true;
            updateMessage("Ваш ход");
        }
    }

    // Функция для выполнения случайного хода
    function makeRandomMove() {
        const available = board
            .map((val, idx) => (val === null ? idx : null))
            .filter((val) => val !== null);
        if (available.length > 0) {
            const randomIndex =
                available[Math.floor(Math.random() * available.length)];
            board[randomIndex] = "O";
            cells[randomIndex].textContent = "O";
            if (checkWin("O")) {
                updateMessage("Компьютер выиграл!");
                gameActive = false;
                return;
            }
            if (checkDraw()) {
                updateMessage("Ничья!");
                gameActive = false;
                return;
            }
            isPlayerTurn = true;
            updateMessage("Ваш ход");
        }
    }

    // Функция для нахождения лучшего хода
    function findBestMove(player) {
        for (let i = 0; i < board.length; i++) {
            if (board[i] === null) {
                board[i] = player;
                if (checkWin(player)) {
                    board[i] = null;
                    return i;
                }
                board[i] = null;
            }
        }
        return -1; // Нет очевидного лучшего хода
    }

    // Функция для перезапуска игры
    function restartGame() {
        board = Array(9).fill(null);
        isPlayerTurn = true;
        gameActive = true;
        cells.forEach((cell) => (cell.textContent = ""));
        updateMessage("Ваш ход");
    }

    // Обработчик кликов по ячейкам
    cells.forEach((cell) => {
        cell.addEventListener("click", () => {
            playerMove(parseInt(cell.dataset.index));
        });
    });

    // Обработчик кнопки рестарта
    restartButton.addEventListener("click", () => {
        restartGame();
    });

    // Добавляем функциональность закрытия окна
    gameCloseButton.addEventListener("click", () => {
        gameContainer.style.display = "none";
    });

    // Реализация перетаскивания окна
    let isDragging = false;
    let offsetX, offsetY;

    gameHeader.addEventListener("mousedown", (e) => {
        isDragging = true;
        offsetX = e.clientX - gameContainer.offsetLeft;
        offsetY = e.clientY - gameContainer.offsetTop;
        document.body.style.userSelect = "none"; // Отключаем выделение текста на странице
    });

    document.addEventListener("mousemove", (e) => {
        if (isDragging) {
            gameContainer.style.left = e.clientX - offsetX + "px";
            gameContainer.style.top = e.clientY - offsetY + "px";
        }
    });

    document.addEventListener("mouseup", () => {
        isDragging = false;
        document.body.style.userSelect = "auto"; // Включаем выделение текста на странице
    });

    // Открытие окна для игры по Shift + A
    document.addEventListener("keydown", (e) => {
        if (e.shiftKey && e.code === "KeyA") {
            e.preventDefault();
            const isHidden = gameContainer.style.display === "none";
            gameContainer.style.display = isHidden ? "block" : "none";
            if (isHidden) {
                restartGame();
            }
        }
    });
});
