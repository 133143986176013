document.addEventListener("DOMContentLoaded", () => {
    const isMobileDevice = () => {
        return window.matchMedia("(max-width: 991px)").matches;
    };

    // if (isMobileDevice()) return;
    const initStack = () => {
        const services = document.querySelectorAll(".clients__section");

        // Check if there are any service sections
        if (services.length === 0) return;

        gsap.registerPlugin(ScrollTrigger);
        const servicesHeaders = document.querySelectorAll(".clients__section");
        const navbarHeight = document.querySelector(".navbar").offsetHeight;
        servicesHeaders.forEach((header, index) => {
            if(index === 2) {
                ScrollTrigger.create({
                    trigger: header,
                    start: `top +=${navbarHeight}`,
                    end: `.team`,
                    pin: true,
                    pinSpacer: false,
                });
                return;
            }
            ScrollTrigger.create({
                trigger: header,
                start: `top +=${navbarHeight}`,
                end: `bottom`,
                pin: true,
                pinSpacer: false,
            });
            gsap.set(header, {
                "z-index": index + 20,
            })
        });
    };

    initStack();
});
