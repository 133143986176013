document.addEventListener("DOMContentLoaded", () => {
    const isMobileDevice = () => {
        return window.matchMedia("(max-width: 991px)").matches;
    };

    const contentSections = document.querySelectorAll(".why-mobile__section");

    //if (contentSections.length === 0 && !isMobileDevice()) return;

    contentSections.forEach((section) => {
        const header = section.querySelector(".why-mobile__section-header");
        const content = section.querySelector(".why-mobile__section-content");

        if (!header || !content) return; // Skip if header or content is not found

        let isOpen = false; // Initialize isOpen

        header.addEventListener("click", () => {
            section.classList.toggle("open");
            isOpen = section.classList.contains("open"); // Update isOpen

            // Determine content height
            const contentHeight = isOpen ? content.scrollHeight : 0;

            gsap.to(content, {
                height: contentHeight,
                duration: 0.2,
                ease: "power1.inOut",
                onComplete: () => {
                    // Ensure the height is set to '0' after animation completes
                    if (!isOpen) {
                        content.style.height = "0";
                    }
                },
            });
        });
    });
});
