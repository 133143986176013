const isMobileDevice = () => {
    return window.matchMedia("(max-width: 991px)").matches;
};

document.addEventListener("DOMContentLoaded", () => {
    if (!isMobileDevice()) return;

    const initStack = () => {
        const services = document.querySelectorAll(".service__section");

        // Check if there are any service sections
        if (services.length === 0) return;

        gsap.registerPlugin(ScrollTrigger);
        const servicesHeaders = document.querySelectorAll(".service__header");
        const navbarHeight = document.querySelector(".navbar").offsetHeight - 2;
        servicesHeaders.forEach((header) => {
            ScrollTrigger.create({
                trigger: header,
                start: `top +=${navbarHeight}`,
                end: `+=${header.parentElement.offsetHeight}`,
                pin: true,
                pinSpacing: false,
            });
        });
    };

    initStack();
});
