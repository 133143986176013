document.addEventListener("DOMContentLoaded", () => {
    const snakeGrid = document.querySelector(".snake-grid");

    if (snakeGrid === undefined || snakeGrid === null) {
        return;
    }

    if (isMobileDevice()) {
        gsap.set({
            display: "none",
        });
    }

    fillGridWithSpans(snakeGrid);
    cellsHover(snakeGrid);
});

// Function to calculate the number of grid cells
function calculateGridCells(div) {
    // Get the computed style of the div
    const style = window.getComputedStyle(div);

    // Get the values of grid-template-columns and grid-template-rows
    const columns = style.getPropertyValue("grid-template-columns");
    const rows = style.getPropertyValue("grid-template-rows");

    // Calculate the number of columns and rows
    const numColumns = columns.split(" ").length;
    const numRows = rows.split(" ").length;

    // Calculate the total number of grid cells
    const totalCells = numColumns * numRows;

    return totalCells;
}

// Function to fill the grid with span elements
function fillGridWithSpans(div) {
    // Calculate the number of grid cells
    const totalCells = calculateGridCells(div);

    // Create and append span elements to the grid container
    for (let i = 0; i < totalCells; i++) {
        const span = document.createElement("span");
        div.appendChild(span);
    }
}

function cellsHover(snake) {
    const cells = snake.querySelectorAll("span");

    cells.forEach((cell) => {
        cell.addEventListener("mouseenter", () => {
            cell.style.opacity = 1;

            setTimeout(() => {
                cell.style.opacity = 0;
            }, 500);
        });
    });
}
