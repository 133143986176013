document.addEventListener("DOMContentLoaded", () => {
    if (window.innerWidth >= 991) {
        const cursorPointed = document.querySelector(".pointed");

        // Function to move the cursor to the mouse position
        const moveCursor = (e) => {
            // Get the Y-coordinate of the mouse pointer and add the scroll offset
            const mouseY = e.clientY - 15;
            // Get the X-coordinate of the mouse pointer and add the scroll offset
            const mouseX = e.clientX - 15;

            cursorPointed.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
        };

        document.addEventListener("mousemove", moveCursor);

        const cursorClickHandler = (e) => {
            cursorPointed.classList.toggle("active");
        };

        // Add event listeners
        document.addEventListener("mousedown", cursorClickHandler);
        document.addEventListener("mouseup", cursorClickHandler);

        // // Add scroll event listener
        // window.addEventListener('scroll', (e) => {
        //     const activeCursor = document.querySelector('.pointed.active');
        //     if (activeCursor) {
        //         // Get the current transform values
        //         const transform = activeCursor.style.transform;
        //         const values = transform.match(/-?\d+\.?\d*/g);
        //         if (values) {
        //             const mouseX = parseFloat(values[0]) - window.scrollX;
        //             const mouseY = parseFloat(values[1]) - window.scrollY;
        //             activeCursor.style.transform = `translate3d(${mouseX + window.scrollX}px, ${mouseY + window.scrollY}px, 0)`;
        //         }
        //     }
        // });
    }
});
