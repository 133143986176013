document.addEventListener("DOMContentLoaded", () => {
    const isMobileDevice = () => {
        return window.matchMedia("(max-width: 991px)").matches;
    };

    if (isMobileDevice()) return;
    const initStack = () => {
        const services = document.querySelectorAll(".service__section");

        // Check if there are any service sections
        if (services.length === 0) return;

        gsap.registerPlugin(ScrollTrigger);
        const servicesHeaders = document.querySelectorAll(".service__header");
        const navbarHeight = document.querySelector(".navbar").offsetHeight;
        servicesHeaders.forEach((header, index) => {
            ScrollTrigger.create({
                trigger: header,
                start: `top +=${navbarHeight}`,
                end: `+=${header.parentElement.offsetHeight - 0}`,
                pin: true,
                pinSpacer: false,
            });
        });
    };

    initStack();
});
