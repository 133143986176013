document.addEventListener("DOMContentLoaded", () => {
    // Создаем контейнер для текстового ввода CSS стилей
    const styleContainer = document.createElement("div");
    styleContainer.style.position = "fixed";
    styleContainer.style.top = "100px";
    styleContainer.style.left = "100px";
    styleContainer.style.zIndex = "9999";
    styleContainer.style.display = "none"; // Скрываем контейнер по умолчанию
    styleContainer.style.background = "white";
    styleContainer.style.border = "1px solid #ccc";
    styleContainer.style.boxShadow = "0 0 10px rgba(0,0,0,0.5)";
    styleContainer.style.width = "740px";
    styleContainer.style.height = "auto";
    styleContainer.style.resize = "both"; // Возможность изменения размера
    styleContainer.style.overflow = "hidden"; // Скрываем переполнение

    // Создаем заголовок окна
    const windowHeader = document.createElement("div");
    windowHeader.style.width = "100%";
    windowHeader.style.height = "30px";
    windowHeader.style.background = "#005745";
    windowHeader.style.cursor = "move";
    windowHeader.style.display = "flex";
    windowHeader.style.justifyContent = "space-between";
    windowHeader.style.alignItems = "center";
    windowHeader.style.color = "white";
    windowHeader.style.boxSizing = "border-box";
    windowHeader.style.padding = "0 10px";

    // Текст заголовка
    const headerTitle = document.createElement("span");
    headerTitle.textContent = "Styles Editor";
    headerTitle.style.userSelect = "none";

    // Кнопка закрытия
    const closeButton = document.createElement("button");
    closeButton.textContent = "X";
    closeButton.style.background = "transparent";
    closeButton.style.border = "none";
    closeButton.style.color = "white";
    closeButton.style.fontSize = "16px";
    closeButton.style.cursor = "pointer";
    closeButton.style.userSelect = "none";
    closeButton.style.transition = "color 0.3s";

    // Добавляем анимацию при наведении на крестик
    closeButton.addEventListener("mouseenter", () => {
        closeButton.style.color = "red";
    });
    closeButton.addEventListener("mouseleave", () => {
        closeButton.style.color = "white";
    });

    // Добавляем заголовок и кнопку в header
    windowHeader.appendChild(headerTitle);
    windowHeader.appendChild(closeButton);

    // Создаем контейнер для редактора
    const editorContainer = document.createElement("div");
    editorContainer.style.width = "100%";
    editorContainer.style.flexGrow = "1"; // Растягиваем контейнер на доступную высоту
    editorContainer.style.display = "flex";
    editorContainer.style.flexDirection = "column";

    // Создаем элемент для редактора
    const editorPlaceholder = document.createElement("div");
    editorPlaceholder.style.width = "100%";
    editorPlaceholder.style.height = "100%"; // Займет всю высоту контейнера
    editorPlaceholder.style.borderTop = "1px solid #ccc";
    editorPlaceholder.style.borderBottom = "1px solid #ccc";

    // Создаем кнопку для включения/выключения стилей
    const toggleButton = document.createElement("button");
    toggleButton.textContent = "Toggle Styles";
    toggleButton.style.display = "block";
    toggleButton.style.width = "100%";
    toggleButton.style.padding = "5px";
    toggleButton.style.border = "none";
    toggleButton.style.background = "#f1f1f1";
    toggleButton.style.cursor = "pointer";
    toggleButton.style.transition = "opacity 0.3s";

    // Добавляем анимацию при наведении и нажатии на кнопку
    toggleButton.addEventListener("mouseenter", () => {
        toggleButton.style.opacity = "0.8";
    });
    toggleButton.addEventListener("mouseleave", () => {
        toggleButton.style.opacity = "1";
    });
    toggleButton.addEventListener("mousedown", () => {
        toggleButton.style.opacity = "0.6";
    });
    toggleButton.addEventListener("mouseup", () => {
        toggleButton.style.opacity = "0.8";
    });

    // Собираем структуру элементов
    editorContainer.appendChild(editorPlaceholder);
    editorContainer.appendChild(toggleButton);

    styleContainer.appendChild(windowHeader);
    styleContainer.appendChild(editorContainer);
    document.body.appendChild(styleContainer);

    // Получаем сохраненные стили из localStorage и применяем их
    const savedStyles = localStorage.getItem("customStyles");
    const styleElement = document.createElement("style");
    document.head.appendChild(styleElement);
    if (savedStyles) {
        styleElement.textContent = savedStyles;
    }

    // По умолчанию стили отключены
    let stylesEnabled = false;
    styleElement.disabled = !stylesEnabled;

    let editor; // Переменная для редактора

    // Функция для инициализации редактора после загрузки скриптов
    function initializeEditor() {
        editor = CodeMirror(editorPlaceholder, {
            value: savedStyles || "",
            mode: "css",
            lineNumbers: true,
            tabSize: 4,
            indentUnit: 4,
            viewportMargin: Infinity, // Позволяет редактору отображать все строки без прокрутки
            extraKeys: {
                Tab: function (cm) {
                    const spaces = Array(cm.getOption("indentUnit") + 1).join(
                        " "
                    );
                    cm.replaceSelection(spaces);
                },
            },
        });

        // Уменьшаем размер шрифта
        editor.getWrapperElement().style.fontSize = "14px";

        // Устанавливаем высоту редактора на 100%
        editor.getWrapperElement().style.height = "100%";

        // Применяем стили при изменении содержимого редактора
        editor.on("change", () => {
            const styles = editor.getValue();
            localStorage.setItem("customStyles", styles);
            styleElement.textContent = styles;
        });

        // Обновляем размер редактора при изменении размера окна
        new ResizeObserver(() => {
            editor.refresh();
        }).observe(editorPlaceholder);
    }

    // Загружаем CSS CodeMirror
    const codemirrorCSS = document.createElement("link");
    codemirrorCSS.rel = "stylesheet";
    codemirrorCSS.href =
        "https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.65.13/codemirror.min.css";
    document.head.appendChild(codemirrorCSS);

    // Загружаем JavaScript CodeMirror
    const codemirrorScript = document.createElement("script");
    codemirrorScript.src =
        "https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.65.13/codemirror.min.js";
    codemirrorScript.onload = () => {
        // После загрузки CodeMirror загружаем режим CSS
        const cssModeScript = document.createElement("script");
        cssModeScript.src =
            "https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.65.13/mode/css/css.min.js";
        cssModeScript.onload = initializeEditor;
        document.body.appendChild(cssModeScript);
    };
    document.body.appendChild(codemirrorScript);

    // Добавляем событие на кнопку включения/выключения стилей
    toggleButton.addEventListener("click", () => {
        stylesEnabled = !stylesEnabled;
        styleElement.disabled = !stylesEnabled;
    });

    // Открытие окна для ввода стилей по Shift + W
    document.addEventListener("keydown", (e) => {
        if (e.shiftKey && e.code === "KeyW") {
            e.preventDefault();
            styleContainer.style.display =
                styleContainer.style.display === "none" ? "block" : "none";
            if (styleContainer.style.display === "block") {
                editor.refresh(); // Обновляем редактор при открытии окна
            }
        }
    });

    // Добавляем функциональность закрытия окна
    closeButton.addEventListener("click", () => {
        styleContainer.style.display = "none";
    });

    // Реализация перетаскивания окна
    let isDragging = false;
    let offsetX, offsetY;

    windowHeader.addEventListener("mousedown", (e) => {
        isDragging = true;
        offsetX = e.clientX - styleContainer.offsetLeft;
        offsetY = e.clientY - styleContainer.offsetTop;
        document.body.style.userSelect = "none"; // Отключаем выделение текста на странице
    });

    document.addEventListener("mousemove", (e) => {
        if (isDragging) {
            styleContainer.style.left = e.clientX - offsetX + "px";
            styleContainer.style.top = e.clientY - offsetY + "px";
        }
    });

    document.addEventListener("mouseup", () => {
        isDragging = false;
        document.body.style.userSelect = "auto"; // Включаем выделение текста на странице
    });
});
