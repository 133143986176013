document.addEventListener("DOMContentLoaded", () => {
    (function () {
        // Создаем контейнер для игры
        const gameContainer = document.createElement("div");
        gameContainer.style.position = "fixed";
        gameContainer.style.top = "100px";
        gameContainer.style.left = "100px";
        gameContainer.style.zIndex = "9999";
        gameContainer.style.display = "none"; // Скрываем контейнер по умолчанию
        gameContainer.style.background = "white";
        gameContainer.style.border = "1px solid #ccc";
        gameContainer.style.boxShadow = "0 0 10px rgba(0,0,0,0.5)";
        gameContainer.style.resize = "both"; // Возможность изменения размера
        gameContainer.style.overflow = "hidden"; // Скрываем переполнение

        // Определяем исходные размеры окна
        let containerWidth = 420;
        let containerHeight = 900;

        // Проверяем высоту экрана и уменьшаем размеры соответственно
        if (window.innerHeight < 970) {
            containerWidth *= 0.8; // Уменьшаем на 20%
            containerHeight *= 0.8;
        } else {
            containerWidth *= 0.9; // Уменьшаем на 10%
            containerHeight *= 0.9;
        }

        // Устанавливаем размеры окна игры
        gameContainer.style.width = `${containerWidth}px`;
        gameContainer.style.height = `${containerHeight}px`;

        // Создаем заголовок окна
        const gameHeader = document.createElement("div");
        gameHeader.style.width = "100%";
        gameHeader.style.height = "30px";
        gameHeader.style.background = "#005745";
        gameHeader.style.cursor = "move";
        gameHeader.style.display = "flex";
        gameHeader.style.justifyContent = "space-between";
        gameHeader.style.alignItems = "center";
        gameHeader.style.color = "white";
        gameHeader.style.boxSizing = "border-box";
        gameHeader.style.padding = "0 10px";

        // Текст заголовка
        const gameTitle = document.createElement("span");
        gameTitle.textContent = "Tetris Game";
        gameTitle.style.userSelect = "none";

        // Кнопка закрытия
        const gameCloseButton = document.createElement("button");
        gameCloseButton.textContent = "X";
        gameCloseButton.style.background = "transparent";
        gameCloseButton.style.border = "none";
        gameCloseButton.style.color = "white";
        gameCloseButton.style.fontSize = "16px";
        gameCloseButton.style.cursor = "pointer";
        gameCloseButton.style.userSelect = "none";
        gameCloseButton.style.transition = "color 0.3s";

        // Добавляем анимацию при наведении на крестик
        gameCloseButton.addEventListener("mouseenter", () => {
            gameCloseButton.style.color = "red";
        });
        gameCloseButton.addEventListener("mouseleave", () => {
            gameCloseButton.style.color = "white";
        });

        // Добавляем заголовок и кнопку в header
        gameHeader.appendChild(gameTitle);
        gameHeader.appendChild(gameCloseButton);

        // Создаем canvas для игры
        const gameCanvas = document.createElement("canvas");
        gameCanvas.style.display = "block";
        gameCanvas.style.margin = "10px auto"; // Центрирование canvas
        gameCanvas.style.border = "2px solid #000"; // Добавляем рамку для игрового поля
        const ctx = gameCanvas.getContext("2d");

        // Создаем область для уведомлений, рекордов и подсказок по управлению
        const infoContainer = document.createElement("div");
        infoContainer.style.width = "100%";
        infoContainer.style.textAlign = "center";
        infoContainer.style.padding = "10px";
        infoContainer.style.boxSizing = "border-box";

        const scoreDisplay = document.createElement("div");
        scoreDisplay.textContent = "Score: 0";
        scoreDisplay.style.marginBottom = "5px";

        const highScoreDisplay = document.createElement("div");
        highScoreDisplay.textContent = "High Score: 0";
        highScoreDisplay.style.marginBottom = "10px";

        const messageDisplay = document.createElement("div");
        messageDisplay.textContent = "";
        messageDisplay.style.color = "red";
        messageDisplay.style.marginBottom = "10px";

        // Добавляем подсказки по управлению
        const controlsHint = document.createElement("div");
        controlsHint.innerHTML = `Используйте стрелки для управления фигурками`;
        controlsHint.style.marginBottom = "10px";
        controlsHint.style.color = "#333";

        // Создаем кнопку рестарта
        const restartButton = document.createElement("button");
        restartButton.textContent = "Restart Game";
        restartButton.style.padding = "5px 10px";
        restartButton.style.border = "none";
        restartButton.style.background = "#005745";
        restartButton.style.color = "white";
        restartButton.style.cursor = "pointer";
        restartButton.style.borderRadius = "4px";
        restartButton.style.transition = "background 0.3s";

        // Добавляем анимацию при наведении на кнопку
        restartButton.addEventListener("mouseenter", () => {
            restartButton.style.background = "#005a9e";
        });
        restartButton.addEventListener("mouseleave", () => {
            restartButton.style.background = "#005745";
        });

        // Собираем структуру элементов
        infoContainer.appendChild(scoreDisplay);
        infoContainer.appendChild(highScoreDisplay);
        infoContainer.appendChild(messageDisplay);
        infoContainer.appendChild(controlsHint); // Добавляем подсказки по управлению
        infoContainer.appendChild(restartButton);

        gameContainer.appendChild(gameHeader);
        gameContainer.appendChild(gameCanvas);
        gameContainer.appendChild(infoContainer);
        document.body.appendChild(gameContainer);

        // Инициализация игры
        const ROWS = 20;
        const COLS = 10;
        let BLOCK_SIZE = 30; // Изменено на let для возможности изменения размера

        const COLORS = [
            null,
            "cyan",
            "blue",
            "orange",
            "yellow",
            "green",
            "purple",
            "red",
        ];

        const SHAPES = [
            [],
            [[1, 1, 1, 1]], // I
            [
                [2, 2, 2],
                [0, 0, 2],
            ], // J
            [
                [3, 3, 3],
                [3, 0, 0],
            ], // L
            [
                [4, 4],
                [4, 4],
            ], // O
            [
                [0, 5, 5],
                [5, 5, 0],
            ], // S
            [
                [0, 6, 0],
                [6, 6, 6],
            ], // T
            [
                [7, 7, 0],
                [0, 7, 7],
            ], // Z
        ];

        let grid = createGrid();
        let currentPiece = null;
        let nextPiece = null;
        let position = { x: 0, y: 0 };
        let score = 0;
        let highScore = parseInt(localStorage.getItem("tetrisHighScore")) || 0;
        highScoreDisplay.textContent = `High Score: ${highScore}`;
        let gameInterval = null;
        let gameOver = false;

        // Функция создания пустой сетки
        function createGrid() {
            return Array.from({ length: ROWS }, () => Array(COLS).fill(0));
        }

        // Функция получения случайной фигуры
        function getRandomPiece() {
            const type = Math.floor(Math.random() * (SHAPES.length - 1)) + 1;
            const shape = SHAPES[type];
            return {
                type,
                shape,
                rotation: 0,
            };
        }

        // Функция поворота фигуры
        function rotate(piece) {
            const rotated = piece.shape[0].map((_, index) =>
                piece.shape.map((row) => row[index]).reverse()
            );
            return {
                ...piece,
                shape: rotated,
            };
        }

        // Функция проверки столкновений
        function collide(grid, piece, pos) {
            for (let y = 0; y < piece.shape.length; y++) {
                for (let x = 0; x < piece.shape[y].length; x++) {
                    if (piece.shape[y][x]) {
                        const newX = pos.x + x;
                        const newY = pos.y + y;
                        if (
                            newX < 0 ||
                            newX >= COLS ||
                            newY >= ROWS ||
                            (newY >= 0 && grid[newY][newX])
                        ) {
                            return true;
                        }
                    }
                }
                return false;
            }
        }

        // Функция объединения фигуры с сеткой
        function merge(grid, piece, pos) {
            piece.shape.forEach((row, y) => {
                row.forEach((value, x) => {
                    if (value) {
                        const newX = pos.x + x;
                        const newY = pos.y + y;
                        if (
                            newY >= 0 &&
                            newX >= 0 &&
                            newX < COLS &&
                            newY < ROWS
                        ) {
                            grid[newY][newX] = piece.type;
                        }
                    }
                });
            });
        }

        // Функция удаления заполненных строк
        function sweep(grid) {
            let lines = 0;
            grid = grid.filter((row) => {
                if (row.every((cell) => cell !== 0)) {
                    lines++;
                    return false;
                }
                return true;
            });
            while (grid.length < ROWS) {
                grid.unshift(Array(COLS).fill(0));
            }
            return { grid, lines };
        }

        // Функция рисования сетки
        function drawGrid() {
            ctx.fillStyle = "#fff";
            ctx.fillRect(0, 0, gameCanvas.width, gameCanvas.height);
            for (let y = 0; y < ROWS; y++) {
                for (let x = 0; x < COLS; x++) {
                    if (grid[y][x]) {
                        ctx.fillStyle = COLORS[grid[y][x]];
                        ctx.fillRect(
                            x * BLOCK_SIZE,
                            y * BLOCK_SIZE,
                            BLOCK_SIZE,
                            BLOCK_SIZE
                        );
                        ctx.strokeStyle = "#000";
                        ctx.strokeRect(
                            x * BLOCK_SIZE,
                            y * BLOCK_SIZE,
                            BLOCK_SIZE,
                            BLOCK_SIZE
                        );
                    }
                }
            }
            if (currentPiece) {
                ctx.fillStyle = COLORS[currentPiece.type];
                currentPiece.shape.forEach((row, y) => {
                    row.forEach((value, x) => {
                        if (value) {
                            const newX = position.x + x;
                            const newY = position.y + y;
                            if (newY >= 0) {
                                ctx.fillRect(
                                    newX * BLOCK_SIZE,
                                    newY * BLOCK_SIZE,
                                    BLOCK_SIZE,
                                    BLOCK_SIZE
                                );
                                ctx.strokeStyle = "#000";
                                ctx.strokeRect(
                                    newX * BLOCK_SIZE,
                                    newY * BLOCK_SIZE,
                                    BLOCK_SIZE,
                                    BLOCK_SIZE
                                );
                            }
                        }
                    });
                });
            }
        }

        // Функция обновления игры
        function update() {
            if (
                collide(grid, currentPiece, {
                    x: position.x,
                    y: position.y + 1,
                })
            ) {
                merge(grid, currentPiece, position);
                const result = sweep(grid);
                grid = result.grid;
                score += result.lines * 100;
                scoreDisplay.textContent = `Score: ${score}`;
                if (score > highScore) {
                    highScore = score;
                    localStorage.setItem("tetrisHighScore", highScore);
                    highScoreDisplay.textContent = `High Score: ${highScore}`;
                }
                currentPiece = nextPiece || getRandomPiece();
                nextPiece = getRandomPiece();
                position = {
                    x:
                        Math.floor(COLS / 2) -
                        Math.floor(currentPiece.shape[0].length / 2),
                    y: 0,
                };
                if (collide(grid, currentPiece, position)) {
                    gameOver = true;
                    clearInterval(gameInterval);
                    messageDisplay.textContent = `Game Over! Your score: ${score}`;
                }
            } else {
                position.y += 1;
            }
        }

        // Функция отрисовки игры
        function draw() {
            drawGrid();
        }

        // Функция для настройки размера канваса и блоков
        function adjustCanvasSize() {
            const containerWidth = gameContainer.clientWidth;
            const containerHeight =
                gameContainer.clientHeight -
                gameHeader.clientHeight -
                infoContainer.clientHeight -
                20; // Учтите padding и margin

            const blockWidth = containerWidth / COLS;
            const blockHeight = containerHeight / ROWS;

            BLOCK_SIZE = Math.min(blockWidth, blockHeight);

            gameCanvas.width = BLOCK_SIZE * COLS;
            gameCanvas.height = BLOCK_SIZE * ROWS;
        }

        // Главная функция игры
        function gameLoop() {
            update();
            draw();
        }

        // Функция инициализации игры
        function initGame() {
            grid = createGrid();
            currentPiece = getRandomPiece();
            nextPiece = getRandomPiece();
            position = {
                x:
                    Math.floor(COLS / 2) -
                    Math.floor(currentPiece.shape[0].length / 2),
                y: 0,
            };
            score = 0;
            scoreDisplay.textContent = `Score: ${score}`;
            messageDisplay.textContent = "";
            gameOver = false;
            if (gameInterval) clearInterval(gameInterval);
            gameInterval = setInterval(gameLoop, 200); // Увеличена частота обновления до 200ms
        }

        // Управление фигурами с клавиатуры
        function handleKeyDown(e) {
            if (!currentPiece || gameOver) return;
            switch (e.code) {
                case "ArrowLeft":
                    if (
                        !collide(grid, currentPiece, {
                            x: position.x - 1,
                            y: position.y,
                        })
                    ) {
                        position.x -= 1;
                    }
                    break;
                case "ArrowRight":
                    if (
                        !collide(grid, currentPiece, {
                            x: position.x + 1,
                            y: position.y,
                        })
                    ) {
                        position.x += 1;
                    }
                    break;
                case "ArrowDown":
                    if (
                        !collide(grid, currentPiece, {
                            x: position.x,
                            y: position.y + 1,
                        })
                    ) {
                        position.y += 1;
                    }
                    break;
                case "ArrowUp":
                    const rotated = rotate(currentPiece);
                    if (!collide(grid, rotated, position)) {
                        currentPiece = rotated;
                    }
                    break;
            }
        }

        // Открытие окна для игры по Shift + D
        document.addEventListener("keydown", (e) => {
            if (e.shiftKey && e.code === "KeyD") {
                e.preventDefault();
                gameContainer.style.display =
                    gameContainer.style.display === "none" ? "block" : "none";
                if (gameContainer.style.display === "block") {
                    if (!gameInterval && !gameOver) {
                        adjustCanvasSize(); // Настраиваем размер канваса
                        initGame(); // Запускаем игру при открытии окна
                    }
                } else {
                    clearInterval(gameInterval);
                    gameInterval = null; // Останавливаем игру при закрытии окна
                }
            }
        });

        // Обработчик кнопки рестарта
        restartButton.addEventListener("click", () => {
            initGame();
        });

        // Добавляем функциональность закрытия окна
        gameCloseButton.addEventListener("click", () => {
            gameContainer.style.display = "none";
            clearInterval(gameInterval);
            gameInterval = null; // Останавливаем игру при закрытии окна
        });

        // Реализация перетаскивания окна
        let isDragging = false;
        let offsetX, offsetY;

        gameHeader.addEventListener("mousedown", (e) => {
            isDragging = true;
            offsetX = e.clientX - gameContainer.offsetLeft;
            offsetY = e.clientY - gameContainer.offsetTop;
            document.body.style.userSelect = "none"; // Отключаем выделение текста на странице
        });

        document.addEventListener("mousemove", (e) => {
            if (isDragging) {
                gameContainer.style.left = e.clientX - offsetX + "px";
                gameContainer.style.top = e.clientY - offsetY + "px";
            }
        });

        document.addEventListener("mouseup", () => {
            isDragging = false;
            document.body.style.userSelect = "auto"; // Включаем выделение текста на странице
        });

        // Отключение прокрутки страницы при нажатии стрелок, когда окно игры открыто
        document.addEventListener("keydown", (e) => {
            const isGameOpen = gameContainer.style.display === "block";
            const arrowKeys = [
                "ArrowUp",
                "ArrowDown",
                "ArrowLeft",
                "ArrowRight",
            ];
            if (isGameOpen && arrowKeys.includes(e.code)) {
                e.preventDefault();
                handleKeyDown(e);
            }
        });

        // Отслеживание изменений размера контейнера
        const resizeObserver = new ResizeObserver(() => {
            adjustCanvasSize();
            draw(); // Перерисовываем канвас после изменения размера
        });

        resizeObserver.observe(gameContainer);
    })();
});
