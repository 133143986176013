document.addEventListener("DOMContentLoaded", () => {
    const uiSections = document.querySelectorAll(".why__ui-section");
    const contentSections = document.querySelectorAll(".why__content-inner");

    // Function to check if the device is mobile
    const isMobileDevice = () => {
        return window.matchMedia("(max-width: 991px)").matches;
    };

    // If not a mobile device and no sections exist, return
    if (uiSections.length === 0 && isMobileDevice()) return;

    uiSections.forEach((section, index) => {
        section.addEventListener("click", () => {
            // Remove the active class from all sections
            uiSections.forEach((s) =>
                s.classList.remove("why__ui-section--active")
            );
            // Add active class to the clicked section
            section.classList.add("why__ui-section--active");

            // Hide all content sections
            contentSections.forEach((content) => {
                content.style.display = "none";
            });

            // Display the corresponding content section
            const contentToShow = document.querySelector(
                `.why__content-inner--0${index + 1}`
            );
            if (contentToShow) {
                contentToShow.style.display = "block";
            }
        });
    });
});
