document.addEventListener("DOMContentLoaded", () => {
    const header = document.querySelector(".cover__header-h1");
    const subheader = document.querySelector(".cover__subheader-p");
    if (header === null || subheader === null) return;

    // Initialize SplitText for header and subheader
    const headerSplit = new SplitText(header, { type: "chars, words, lines" });
    const subheaderSplit = new SplitText(subheader, {
        type: "chars, words, lines",
    });

    const tl = gsap.timeline({ delay: 3.5 });

    // Set initial styles to hide overflow for lines
    gsap.set(headerSplit.lines, {
        overflow: "hidden",
    });
    gsap.set(subheaderSplit.lines, {
        overflow: "hidden",
    });

    // Animate header words from y: 100% to y: 0%
    tl.fromTo(
        headerSplit.words,
        { y: "100%" }, // Starting state
        { y: "0%", stagger: 0.05, duration: 0.4, ease: "power2.out" }
    );

    // Animate subheader words from y: 100% to y: 0%
    tl.fromTo(
        subheaderSplit.words,
        { y: "100%" }, // Starting state
        { y: "0%", stagger: 0.03, duration: 0.1, ease: "power2.out" },
        "-=0.2" // Overlap the animations slightly if desired
    );
});
